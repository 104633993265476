<template>
  <section
    id="recent-projects"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="primary text-center pa-13 white--text"
        cols="12"
        md="12"
      >
        <!--base-subheading>
          Unser Angebot
        </base-subheading-->

        <base-text class="mb-5">
          Den richtigen Partner
          für alle Bereiche. Vom Konzept über
          die Planung bis zur perfekten Umsetzung.
          Alles aus einer Hand.
        </base-text>

        <!--v-container class="pa-2">
          <v-row>
            <v-col

              cols="12"
              md="6"
            />
          </v-row>
        </v-container>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/harrison.jpg')"
          height="100%"
        />
      </v-col-->
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'RecentProjects',

    data: () => ({
      projects: [
        'project1',
        'project2',
        'project3',
        'project4',
      ],
    }),
  }
</script>
